import { useStore } from '@nanostores/react'
import { useEffect } from 'react'
// import Swiper core and required modules
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/scss'
import 'swiper/scss/pagination'

import { MealProgramSubscription } from '@/models/meal-program-subscription'
import { ProductSubscription } from '@/models/product-subscription'
import {
  loadSubscriptionCampaigns,
  loadSubscriptions,
} from '@/stores/account/operations'
import { $customer } from '@/stores/account/stores'

import CleanBoutiqueSubscriptionCard from './CleanBoutiqueSubscriptionCard/CleanBoutiqueSubscriptionCard'
import MealProgramSubscriptionCard from './MealProgramSubscriptionCard/MealProgramSubscriptionCard'

import './CustomerSubscriptions.scss'
import { $activeSubscriptions } from '@/stores/account/computed'

const DEFAULT_NUMBER_OF_SUBSCRIPTIONS_DISPLAYED = 3
const DEFAULT_SORT_BY = 'preferred'

const CustomerSubscriptions = () => {
  const activeSubscriptions = useStore($activeSubscriptions)
  const customer = useStore($customer)

  const fetchSubscriptions = async (
    page: number,
    perPage: number,
    force = false,
  ) => {
    const options = {
      page,
      perPage,
      sortBy: DEFAULT_SORT_BY,
      force: force,
    }
    await loadSubscriptions(options)
  }

  useEffect(() => {
    if (!customer) return

    fetchSubscriptions(1, DEFAULT_NUMBER_OF_SUBSCRIPTIONS_DISPLAYED + 1)
    loadSubscriptionCampaigns({ customerId: customer.simpleId })
  }, [])

  const getComponentType = (subscription: any) => {
    let componentType

    if (subscription instanceof MealProgramSubscription) {
      componentType = MealProgramSubscriptionCard
    } else if (subscription instanceof ProductSubscription) {
      componentType = CleanBoutiqueSubscriptionCard
    }

    return componentType
  }

  if (!activeSubscriptions.length) {
    return null
  }

  const subscriptionsToDisplay = activeSubscriptions.slice(
    0,
    DEFAULT_NUMBER_OF_SUBSCRIPTIONS_DISPLAYED,
  )

  return (
    <div className="c-customer-subscriptions">
      <h2 className="c-customer-subscriptions__heading type-label">
        Subscriptions
      </h2>
      <div className="c-customer-subscriptions__list">
        <div className="c-customer-subscriptions__list-wrapper">
          {subscriptionsToDisplay.map((subscription: any) => {
            const ComponentType = getComponentType(subscription)
            return (
              <div
                key={subscription.id}
                className="c-customer-subscriptions__subscription"
              >
                {ComponentType && <ComponentType subscription={subscription} />}
              </div>
            )
          })}
        </div>
        {activeSubscriptions.length >
          DEFAULT_NUMBER_OF_SUBSCRIPTIONS_DISPLAYED && (
          <a
            href={'/account?tab_id=subscriptions'}
            className="c-customer-subscriptions__load-more button button--primary o-loadable o-loadable--small o-loadable--inverted"
          >
            Manage all subscriptions
          </a>
        )}
      </div>
      <Swiper
        className="swiper-container"
        modules={[Pagination]}
        spaceBetween={32}
        slidesPerView={1}
        pagination={{
          el: '#c-customer-subscriptions__swiper-pagination',
          clickable: true,
        }}
      >
        {activeSubscriptions.map((subscription: any, index: number) => {
          if (index > 2) {
            return (
              <SwiperSlide
                key="manage-all-subscriptions"
                className="swiper-slide swiper-slide-end"
              >
                <a
                  href={'/account?tab_id=subscriptions'}
                  className="c-customer-subscriptions__load-more-mobile button button--primary o-loadable o-loadable--small o-loadable--inverted"
                >
                  Manage all subscriptions
                </a>
              </SwiperSlide>
            )
          }

          const ComponentType = getComponentType(subscription)
          return (
            <SwiperSlide key={subscription.id} className="swiper-slide">
              {ComponentType && <ComponentType subscription={subscription} />}
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div
        id="c-customer-subscriptions__swiper-pagination"
        className="swiper-pagination"
      />
    </div>
  )
}

export default CustomerSubscriptions
