import { useStore } from '@nanostores/react'
import { useMemo } from 'react'

import { $getCampaignsBySubscriptionId } from '@/stores/account/computed'
import dayjs from '@/utilities/dayjs-config'
import imgUrl from '@/utilities/imgUrl'
import { formatDate, formatMeals } from '@/utilities/sakara'

import FailedPaymentNotice from '../FailedPaymentNotice/FailedPaymentNotice'
import SubscriptionCard from '../SubscriptionCard/SubscriptionCard'
import SubscriptionCardNoticeContainer from '@/components/SubscriptionCardNotice/SubscriptionCardNoticeContainer'

import './MealProgramSubscriptionCard.scss'

const IMAGE_SRC = imgUrl(
  'https://cdn.shopify.com/s/files/1/0192/0076/files/meal-program-subscription.png?v=1650377978',
  '200x200',
)

const MealProgramSubscriptionCard = ({ subscription }: any) => {
  // TODO: Revisit this logic
  const isExtraInfoLoading = false
  const skippedWeeks: string[] = []

  const getCampaignsBySubscriptionId = useStore($getCampaignsBySubscriptionId)

  const calendarUrl = useMemo(() => {
    return `/calendar?subscription_id=${subscription.id}`
  }, [subscription.id])

  const accountUrl = useMemo(() => {
    return `/account?tab_id=subscriptions&subscription_id=${subscription.id}`
  }, [subscription.id])

  const campaigns = useMemo(() => {
    return getCampaignsBySubscriptionId(subscription.id) || []
  }, [getCampaignsBySubscriptionId, subscription])

  const upcomingCampaigns = useMemo(() => {
    const today = dayjs()

    return campaigns.filter((campaign) => {
      const { programLength, optInStatus } = campaign
      const campaignStartWeek = optInStatus!.campaignStartWeek

      const campaignEndWeek = dayjs(campaignStartWeek).add(
        programLength - 1,
        'weeks',
      )
      const endOfCampaign = campaignEndWeek.endOf('week')

      return endOfCampaign.isAfter(today) && !subscription.isCancelled
    })
  }, [campaigns, subscription])

  const upcomingCampaign = useMemo(() => {
    if (!upcomingCampaigns.length) return null

    return upcomingCampaigns[0]
  }, [upcomingCampaigns])

  const upcomingCampaignText = useMemo(() => {
    if (!upcomingCampaign) return ''

    const campaignStartWeek = upcomingCampaign.optInStatus!.campaignStartWeek

    return `${
      upcomingCampaign.config?.title || upcomingCampaign.name
    } starting ${campaignStartWeek.format('M/D')}`
  }, [upcomingCampaign])

  const button = subscription.isCancelled ? (
    <a href={accountUrl} className="button button--secondary button--small">
      Reactivate
    </a>
  ) : (
    <a
      href={calendarUrl}
      className="subscription-button button button--secondary button--small"
    >
      Manage subscription
    </a>
  )

  const cardTitle = subscription.name || 'Signature Nutrition Subscription'

  const info = (
    <>
      <span className="type-label">
        {formatMeals(subscription.meals, ' + ')}
      </span>
      <span className="type-body"> {subscription.daysOfFood} Days / Week</span>
      {upcomingCampaignText && (
        <span className="c-meal-program-subscription-card__upcoming-campaign type-label">
          {upcomingCampaignText}
        </span>
      )}
    </>
  )

  const isUpcomingWeekSkipped = () => {
    const nextBillingDate = dayjs(subscription.nextBillingDate)
    const nextWeekToBeBilled = nextBillingDate.add(1, 'week').startOf('week')

    return skippedWeeks.includes(nextWeekToBeBilled.format())
  }

  const timeUntilMealChangeCutoff = () => {
    const today = dayjs()
    const daysInDecimal = subscription.nextBillingDate.diff(today, 'days', true)
    const days = Math.trunc(daysInDecimal)
    const hours = Math.trunc((daysInDecimal % 1) * 24)

    return `${days} Days, ${hours} Hours`
  }

  const getExtraInfo = () => {
    if (isExtraInfoLoading) {
      return (
        <div className="c-meal-program-subscription-card__extra-info-placeholder" />
      )
    }

    if (subscription.isCancelled) {
      return (
        <span className="type-label">
          Subscription cancelled{' '}
          {formatDate(subscription.endDate, 'MM/DD/YYYY')}
        </span>
      )
    }

    if (isUpcomingWeekSkipped()) {
      return (
        <>
          Your deliveries are <strong className="type-label">paused</strong> for
          next week.
        </>
      )
    }

    return (
      <>
        Review next week within{' '}
        <strong className="type-label">{timeUntilMealChangeCutoff()}</strong>
      </>
    )
  }

  const getNotice = () => {
    if (subscription.isCancelled) {
      return null
    }

    if (subscription.failedPayment) {
      return <FailedPaymentNotice subscription={subscription} />
    } else {
      return <SubscriptionCardNoticeContainer type="MEAL" />
    }
  }

  return (
    <div className="c-meal-program-subscription-card">
      <SubscriptionCard
        imageSrc={IMAGE_SRC}
        isCancelled={subscription.isCancelled}
        notice={getNotice()}
        title={cardTitle}
        info={info}
        extraInfo={getExtraInfo()}
        button={button}
      />
    </div>
  )
}

export default MealProgramSubscriptionCard
